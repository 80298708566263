.app-header, .app-footer {
  padding: 40px 20px 42px 100px;
}

.app-header h1 {
  margin: 0;
  position: relative;
  font-size: 30px;
}

.app-header a {
  color: #000;
  text-decoration: none;
}

.app-header h1::before {
  content: "";
  width: 50px;
  height: 50px;
  background: url('img/pparadox_icon.png') no-repeat;
  background-size: 50px;
  position: absolute;
  top: -2px;
  left: -60px;
}

main {
  display: flex;
  justify-content: center;
}

.main-content {
  padding: 25px;
  min-height: 2000px;
  background: linear-gradient(to bottom, #00aeef, #005aa7);
  position: relative;
}

main h1 {
  font-size: 22px;
  margin: 0 0 25px;
  text-shadow: 0px 0.5px 2px #000;
  color: #fff;
}

.comic-image {
  display: block;
  margin: 0 auto;
  border-radius: 25px;
}

.paginator {
  position: absolute;
  right: 0;
  top: 0;
}

.paginator ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  border-radius: 0 25px 0 0;
}

.paginator .button {
  line-height: 55px;
  background: #28b7ed;
  color: #000;
  font-size: 22px;
  font-weight: 600;
  padding: 0 30px;
  border: 0.5px solid rgba(255,255,255,0.5);
  border-top: 0;
  border-right: 0;
  display: inline-block;
  text-decoration: none;
}

.paginator .button:hover {
  background: #56ab2f;
  background: #0faa0f;
}

.paginator li:first-child .button {
  border-radius: 0 0 0 25px;
}

.paginator li:last-child .button, .paginator li:only-child .button {
  border-radius: 0 25px 0 0;
}

.paginator li:not(:only-child):last-child .button {
  border-left: 0;
}

@media (min-width: 700px) {
  .app-header h1 {
    font-size: 34px;
  }
  .main-content {
    border-radius: 25px;
    min-height: inherit;
  }
}
